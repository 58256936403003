import { getContent } from '../../apis/api.content'
import { useQuery } from 'react-query'
import { useTeam } from '../../pages/Team/Hooks/useTeam'

export const useContent = () => {
    const { courseID } = useTeam()
    const queryKey = [courseID, 'Content']

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getContent(`${courseID}`),
        enabled: !!courseID,
    })

    const getSessionContent = (content: number | string) => {
        if (!data) return undefined

        if (typeof content === 'string') return data.find((item) => item.title === content)

        return data.find((item) => item.from === content)
    }

    const generateRange = (from: number, to: number): number[] =>
        Array.from({ length: to - from + 1 }, (_, i) => from + i)

    return {
        getSessionContent,
        generateRange,
        data,
        ...rest,
    }
}
