import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { UpdateContentData } from '../apis/api.content'
import { useContent } from '../hooks/ApiHooks/useContent'
import { useProfileDayOfContent } from '../hooks/ApiHooks/useProfileDayOfContent'
import styles from './ContentSelect.module.css'

export const ContentSelect = ({
    profileId,
    setChanges,
    setIsDisabled,
}: {
    profileId: string
    setChanges: Dispatch<SetStateAction<UpdateContentData>>
    setIsDisabled: Dispatch<SetStateAction<boolean>>
}) => {
    const { t } = useTranslation('teamManager')
    const { getSessionContent, generateRange, data: sessionsContent } = useContent()
    const { content: profileContent } = useProfileDayOfContent(profileId)

    const [selectedContent, setSelectedContent] = useState<{
        contentTitle: string
        contentNumber: number
    }>({
        contentTitle: '',
        contentNumber: 0,
    })

    const isContentChanged = useMemo(
        () => selectedContent.contentNumber !== profileContent?.contentNumber,
        [selectedContent.contentNumber, profileContent?.contentNumber]
    )

    const setChangesHandler = (newContentNumber: number) => {
        setChanges((prev) => ({
            ...prev,
            content: {
                newContentNumber: newContentNumber,
            },
        }))
    }

    const handleTitleChange = (option: { value: string; label: string } | null) => {
        if (!option) return

        const sessionContent = getSessionContent(option.value)
        if (!sessionContent) return
        setSelectedContent({
            contentTitle: sessionContent.title,
            contentNumber: sessionContent.from,
        })
        setChangesHandler(sessionContent.from)
    }

    const handleNumberChange = (option: { value: number; label: number } | null) => {
        if (!option) return

        setSelectedContent((prev) => ({
            ...prev,
            contentNumber: option.value,
        }))
        setChangesHandler(option.value)
    }

    const titleOptions = useMemo<
        {
            value: string
            label: string
        }[]
    >(
        () =>
            sessionsContent?.map(({ title }) => ({
                value: title,
                label: title,
            })) || [],
        [sessionsContent]
    )

    const numberOptions = useMemo<{ value: number; label: number }[]>(() => {
        const selectedTitle = sessionsContent?.find(
            (content) => content.title === selectedContent.contentTitle
        )
        if (!selectedTitle) return []

        return generateRange(selectedTitle.from, selectedTitle.to).map((num) => ({
            value: num,
            label: num,
        }))
    }, [sessionsContent, selectedContent.contentTitle, generateRange])

    useEffect(() => {
        setSelectedContent({
            contentTitle: profileContent
                ? profileContent.description
                : sessionsContent?.[0]?.title || '',
            contentNumber: profileContent?.contentNumber || sessionsContent?.[0]?.from || 0,
        })
    }, [profileContent, sessionsContent])

    useEffect(() => {
        setIsDisabled(!isContentChanged)
    }, [isContentChanged, setIsDisabled])

    return (
        <div className={styles.inputs}>
            <div>
                <label>{t('user-options.content-modal-title')}</label>
                <Select
                    options={titleOptions}
                    value={titleOptions.find((opt) => opt.value === selectedContent.contentTitle)}
                    onChange={handleTitleChange}
                />
            </div>
            <div>
                <label>{t('user-options.content-modal-content')}</label>
                <Select
                    options={numberOptions}
                    value={numberOptions.find((opt) => opt.value === selectedContent.contentNumber)}
                    onChange={handleNumberChange}
                />
            </div>
        </div>
    )
}
