import { useMutation, useQueryClient } from 'react-query'
import {
    getTeamDetails,
    mentorToOrigin,
    updateAssignation,
    updateMentors,
    updateUnAssigned,
} from '../../apis/api.egg'
import { useUIContext } from '../../context/UIContext'
import { StudentItemProps } from '../../pages/Team/Components/StudentItem/StudentItem'
import { useTeam } from '../../pages/Team/Context/TeamContext'
import { useGAEvents } from '../useGAEvents'

export const useArrange = (to?: number) => {
    const { toastError } = useUIContext()
    const { team, courseID, unassignedPage } = useTeam()
    const { sendGA } = useGAEvents()
    const queryClient = useQueryClient()

    const { mutate: assign, isLoading: isLoadingAssign } = useMutation(
        [courseID, 'Team', 'Rearrange student'],
        (props: Parameters<typeof updateAssignation>[0]) => updateAssignation(props),
        {
            onMutate: () =>
                sendGA({
                    category: 'Team Management',
                    action: 'StudentUnassigned',
                }),

            onError: (error) => {
                errorRecovery()
                toastError(error)
            },
            onSettled: () => {
                queryClient.invalidateQueries([courseID, 'Team', 'Search'], { exact: false }),
                    queryClient.invalidateQueries([courseID, 'Team', 'Details', to])
            },
        }
    )

    const { mutate: returnMentor, isLoading: isLoadingReturnMentor } = useMutation(
        [courseID, 'Team', 'Return mentor'],
        (props: Parameters<typeof mentorToOrigin>[0]) => mentorToOrigin(props),
        {
            onMutate: () =>
                sendGA({
                    category: 'Team Management',
                    action: 'MentorToOrigin',
                }),
            onError: (error) => {
                errorRecovery()
                toastError(error)
            },
            onSettled: () =>
                queryClient.invalidateQueries([courseID, 'Team', 'Search'], { exact: false }),
        }
    )

    const errorRecovery = () => {
        queryClient.invalidateQueries([courseID, 'Team', 'Details'], { exact: false })
        queryClient.invalidateQueries([courseID, 'Team', 'Unassigned'], { exact: false })
        queryClient.invalidateQueries([courseID, 'Team', 'Team Mentors'], { exact: false })
    }

    /** Use to assign & unassign students */
    const rearrange = async (
        student: Partial<StudentItemProps>,
        from: number | 'unassigned',
        to: number | 'unassigned' | 'origin'
    ) => {
        const algorithmID = team?.algorithm?._id
        if (!algorithmID) throw new Error('Can not get algorithm ID.')
        if (!student._profileId) throw new Error('Can not get profile ID.')

        const { _profileId, isMentor } = student

        // If mentor Remove mentor from Modal Mentor
        if (isMentor)
            queryClient.setQueryData<ModalMentorQuery>(
                [courseID, 'Team', 'Team Mentors', to],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              mentors: prev.mentors.filter((std) => std._profileId !== _profileId),
                              integration: prev.integrations.filter(
                                  (std) => std._profileId !== _profileId
                              ),
                          }
                        : prev
            )

        // Remove student from unassigned
        if (from === 'unassigned')
            queryClient.setQueryData<UnAssignedQuery>(
                [courseID, 'Team', 'Unassigned', unassignedPage],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              results: prev.results.filter((std) => std._profileId !== _profileId),
                          }
                        : prev
            )
        else {
            // Remove student from team
            queryClient.setQueryData<TeamDetailsQuery>(
                [courseID, 'Team', 'Details', from],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              students: prev.students.filter(
                                  (std) => std._profileId !== _profileId
                              ),
                          }
                        : prev
            )

            // Appoint new first student as facilitator if not a mentor team
            const snapshot = queryClient.getQueryData<TeamDetailsQuery>([
                courseID,
                'Team',
                'Details',
                from,
            ])
            const isMentorTeam = snapshot?.isMentorTeam
            const nextFacilitatorIndex = snapshot?.students.findIndex(({ isMentor }) => !isMentor)
            !isMentorTeam &&
                queryClient.setQueryData<TeamDetailsQuery>(
                    [courseID, 'Team', 'Details', from],
                    (prev) =>
                        prev
                            ? {
                                  ...prev,
                                  students: prev.students.map((std, index) =>
                                      index === nextFacilitatorIndex
                                          ? { ...std, isFacilitator: true }
                                          : { ...std, isFacilitator: false }
                                  ),
                              }
                            : prev
                )
        }

        // Add student to unassigned
        if (to === 'unassigned')
            queryClient.setQueryData<UnAssignedQuery>(
                [courseID, 'Team', 'Unassigned', unassignedPage],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              results: [
                                  {
                                      ...student,
                                      isMentor: false,
                                      isAssigned: false,
                                      isFacilitator: false,
                                      team: null,
                                      showTeamBadge: false,
                                  },
                                  ...prev.results,
                              ],
                          }
                        : prev
            )
        // Add student to a new team
        else if (typeof to === 'number')
            queryClient.setQueryData<TeamDetailsQuery>([courseID, 'Team', 'Details', to], (prev) =>
                prev
                    ? {
                          ...prev,
                          students: [
                              {
                                  ...student,
                                  isAssigned: true,
                                  isFacilitator: false,
                                  showTeamBadge: false,
                              },
                              ...prev.students,
                          ]
                              .sort((a, b) =>
                                  a.isFacilitator > b.isFacilitator
                                      ? -1
                                      : b.isFacilitator > a.isFacilitator
                                      ? 1
                                      : 0
                              )
                              .sort((a, b) =>
                                  a.isMentor > b.isMentor ? -1 : b.isMentor > a.isMentor ? 1 : 0
                              ),
                      }
                    : prev
            )
        // Add student/mentor to origin team
        else if (to === 'origin' && student.originTeam)
            queryClient.setQueryData<TeamDetailsQuery>(
                [courseID, 'Team', 'Details', student.originTeam],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              students: [
                                  {
                                      ...student,
                                      isAssigned: true,
                                      showTeamBadge: false,
                                  },
                                  ...prev.students,
                              ],
                          }
                        : prev
            )

        // Persist changes
        if (to === 'origin') returnMentor({ algorithmID, _profileId })
        else assign({ algorithmID, _profileId, to })
    }

    const isLoading = isLoadingAssign || isLoadingReturnMentor

    return { rearrange, isLoading }
}

// eslint-disable-next-line no-undef
type TeamDetailsQuery = Awaited<ReturnType<typeof getTeamDetails>> | undefined

// eslint-disable-next-line no-undef
type UnAssignedQuery = Awaited<ReturnType<typeof updateUnAssigned>> | undefined

// eslint-disable-next-line no-undef
type ModalMentorQuery = Awaited<ReturnType<typeof updateMentors>> | undefined
