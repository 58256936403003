import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateContentData } from '../../../../../../apis/api.content'
import ConfirmModal from '../../../../../../components/ConfirmModal'
import { ContentSelect } from '../../../../../../components/ContentSelect'
import { useProfileDayOfContent } from '../../../../../../hooks/ApiHooks/useProfileDayOfContent'
import { useTeam } from '../../../../Context/TeamContext'
import { ModalProps } from '../MoreOptions'
import styles from './ContentModal.module.css'

export const ContentModal = ({ props, isOpen, closeModal }: ModalProps) => {
    const { t } = useTranslation('teamManager')
    const { courseID } = useTeam()
    const { _profileId: profileId, displayName } = props
    const { contentStatus, mutateContent, content } = useProfileDayOfContent(profileId)
    const [isDisabled, setIsDisabled] = useState(true)

    const [changes, setChanges] = useState<UpdateContentData>({
        courseId: `${courseID}`,
        target: 'profiles',
        profileIds: [profileId],
        content: {
            newContentNumber: content?.contentNumber || 0,
        },
    })

    const onConfirm = () => {
        if (!courseID || !changes) return
        mutateContent(changes)
        closeModal()
    }

    return (
        <ConfirmModal
            open={isOpen}
            type={'warning'}
            title={t('actions.content.title-modal', { displayName })}
            confirmButtonText={t('user-options.confirm-btn-modal')}
            cancelButtonText={t('user-options.cancel-btn-modal')}
            onConfirm={onConfirm}
            onClose={closeModal}
            contentText={
                contentStatus === 'loading' ? (
                    <div className={styles.spinner}>
                        <FontAwesomeIcon icon="spinner" spin />
                    </div>
                ) : (
                    <ContentSelect {...{ profileId, setChanges, setIsDisabled }} />
                )
            }
            disabled={contentStatus === 'loading' || isDisabled}
        />
    )
}
